import React from 'react'

const WithoutPrismicPreviewProvider = ({children}: any) => <>{children}</>

const withoutPrismicPreview = (component: any) => component

const componentResolverFromMap = () => null

export {
    WithoutPrismicPreviewProvider as PrismicPreviewProvider,
    withoutPrismicPreview as withPrismicPreview,
    withoutPrismicPreview as withPrismicUnpublishedPreview,
    withoutPrismicPreview as withPrismicPreviewResolver,
    componentResolverFromMap,
}
